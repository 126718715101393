// vendors
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Navbar,
  Alignment,
  Button,
  Toaster,
  Toast,
  MenuItem,
  Menu,
  Position,
} from '@blueprintjs/core';
import styled, { css } from 'styled-components';
import 'styled-components/macro';

// actions
import { removeToast } from '../States/Actions';

// components
import { modifier, useKeys } from './Shortcuts';

import logoSousTitreur from '../Images/logo-sousTitreur.svg';

const Container = styled.div`
  margin-bottom: 0.5em;
  /* z-index: 30; */

  .bp3-navbar {
    background-color: #293742;
    color: white;
    height: var(--sst-titlebar-height);
  }
  .bp3-button {
    color: white;
    border: 1px solid white;
    margin: 0 5px;
    span {
      color: white;
    }
  }
  .bp3-navbar-group > .bp3-menu {
    color: white;
    background-color: rgb(41, 55, 66);
    margin: 0 5px;
    border: 1px solid white;
    padding-top: 0;
    padding-bottom: 0;

    span {
      color: white;
    }
  }

  .bp3-menu-item {
    span {
      color: rgb(41, 55, 66);
    }
  }
`;

const Logo = styled.h1`
  display: flex;
  margin: 0;
`;

const LogoImg = styled.img`
  width: 100%;
  height: 40px;
  max-width: 84px;
`;

const hideVisually = css`
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
`;

const keyMap = {
  SAVE: {
    action: 'Save the project',
    combination: {
      modifiers: [modifier.CTRL],
      key: 's',
    },
  },
  SHARE: {
    action: 'Share the project',
    combination: {
      modifiers: [modifier.CTRL, modifier.SHIFT],
      key: 's',
    },
  },
};

function Titlebar({ onSave, onShare }) {
  const dispatch = useDispatch();

  const toasts = useSelector((state) => state.toasts.toasts);

  const title = useSelector((state) => state.srtData.present.title);
  const language = useSelector((state) => state.srtData.present.language);

  // In case it's a transcript log
  const canSave = useSelector((state) => state.srtData.present.canSave);
  const transcriptLogTimestamp = useSelector((state) => state.srtData.present.transcriptLogTimestamp);
  const employeeName = useSelector((state) => state.srtData.present.employeeName);
  const employeeEmail = useSelector((state) => state.srtData.present.employeeEmail);
  

  const downloadOptions = useSelector((state) =>
    state.srtData.present.downloadOptions.srt.concat(
      state.srtData.present.downloadOptions.tv,
      state.srtData.present.downloadOptions.transcription
    )
  );

  const DownloadOptions = () => (
    <>
      {downloadOptions.length > 0 && (
        <Menu outlined>
          <MenuItem text="Download SRT" position={Position.LEFT_BOTTOM}>
            {downloadOptions.map((option) => (
              <MenuItem
                key={option.url}
                text={option.text}
                icon="share"
                href={`https://soustitreur.com${option.url}`}
                target="_blank"
                rel="noopener noreferrer"
              />
            ))}
          </MenuItem>
        </Menu>
      )}
    </>
  );

  const emitSave = useCallback(() => {
    if (typeof onSave === 'function') onSave();
  }, [onSave]);

  const emitShare = useCallback(() => {
    if (typeof onShare === 'function') onShare();
  }, [onShare]);

  const handleSave = useCallback(
    (e) => {
      e.preventDefault();
      emitSave();
    },
    [emitSave]
  );

  const handleShare = useCallback(
    (e) => {
      e.preventDefault();
      emitShare();
    },
    [emitShare]
  );

  useMemo(() => {
    keyMap['SAVE'].handler = handleSave;
    keyMap['SHARE'].handler = handleShare;
  }, [handleSave, handleShare]);

  useKeys(keyMap);

  return (
    <Container>
      <Navbar>
        <Navbar.Group align={Alignment.LEFT}>
          <Navbar.Heading>
            <Logo>
              <span css={hideVisually}>SousTitreur</span>
              <LogoImg src={logoSousTitreur} alt="SousTitreur" />
            </Logo>
          </Navbar.Heading>
        </Navbar.Group>

        <Navbar.Group align={Alignment.LEFT}>
          <Navbar.Heading>
            <span
              css={`
                font-weight: 500;
                letter-spacing: 0.33px;
              `}
            >
              {title} ({language}). { !canSave && (<> Employee: {employeeName} ({employeeEmail}). Date and time: {transcriptLogTimestamp} </>) }
            </span>
          </Navbar.Heading>
        </Navbar.Group>
        <Navbar.Group align={Alignment.RIGHT}>
          <DownloadOptions />
          <Button
              outlined={true}
              icon="upload"
              text="Share"
              onClick={emitShare}
            />


          {canSave && (
          <Button
            outlined={true}
            icon="floppy-disk"
            text="Save"
            onClick={emitSave}
          />
          )}
        </Navbar.Group>
      </Navbar>

      <Toaster>
        {toasts.map((toast) => (
          <Toast
            key={toast.id}
            message={toast.message}
            timeout={toast.timeout}
            intent={toast.intent}
            icon={toast.icon}
            onDismiss={() => dispatch(removeToast(toast.id))}
          />
        ))}
      </Toaster>
    </Container>
  );
}

export default React.memo(Titlebar);
